.text-component {
  display: flex;
  flex-direction: column;
  padding-top: rem(36);
  padding-bottom: rem(36);
  .tag {
    margin-bottom: rem(20);
    order: -1;
  }
  .heading, .heading-about {
    margin-bottom: rem(14);
  }
  .heading, .heading-about, p {
    order: -1;
  }

  .heading-about {
    color: $purple;
  }

  p {
    display: inline-table;
    // Highlight Animation
    .highlighted-text {
      display: inline;
      background: linear-gradient(to right, rgba(255 255 255 / 0%) 50%, $bronze 50%);
      background-position: 0 0;
      background-size: 200%;
    }    
  }
  .text-link{
    color: $green;
  }

  &.msg-style {
    background-color: $grey-1;
    padding: rem(48) 0 rem(38);
    .msg-container {
      margin: 0 auto;
      padding: 0 rem(24);
      @media (min-width: 576px) {
        padding: 0 rem(24);
        max-width: 540px;
      }
      @media (min-width: 768px){
        max-width: 720px;
      }
      @media (min-width: 992px) {
        max-width: 960px;
      }
      @media (min-width: 1200px) {
        max-width: 1140px;
      }
      @media (min-width: 1400px){
        max-width: 1320px;
      }
      .msg-image {
        margin-bottom: rem(32);
      }
      .msg-name, .msg-position{
        display: block;
        font-size: rem(14);
      }
      .msg-name {
        margin-bottom: rem(1);        
        font-family: HelveticaNeueMedium;
      }
      .msg-position {
        font-family: HelveticaNeue;
      }
    }
  }

  /* Background Options */

  /* 1. Blue tag, white background, black text */
  &.opt1 {
    .tag {
      background-image: $steel-gradient;
    }
  }

  /* 2. Brown tag, black background, white text */
  &.opt2 {
    color: $white;    
    background-color: $grey-8;
    .heading {
      color: $white;
    }
  }
}

b, strong {
  font-family: $font-body-bold;
}